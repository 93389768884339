import React, { useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Row,
  Col,
  Label,
} from "reactstrap"
import { Input, Avatar, Upload, Select, Divider } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import { transferJems } from "clientServices/joshResellerService"
import toastr from "toastr"
import { ResellerListAPIResponse } from "util/types/types"


interface TransferModalProps {
  modalOpen: boolean
  closeModal: () => void
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  selectedReseller: ResellerListAPIResponse
  managerId: string
}

const TransferJemsModal: React.FC<TransferModalProps> = ({
  modalOpen,
  closeModal,
  handleSubmit,
  selectedReseller,
  managerId,
}) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [transferTo, setTransferTo] = useState(selectedReseller.user_uuid)
  const [enteredAmount, setEnteredAmount] = useState("1")

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const basePrice = "0.39"
  const discount = "35"
  const conversionFactor = "5"

  const handleFileChange = (file: File) => {
    setUploadedFile(file)
    return false
  }

  const handleTransferClick = async () => {
    if (!enteredAmount || parseInt(enteredAmount) < 1 || parseInt(enteredAmount) > 1000000) {
      toastr.error(
        "Please enter a valid amount greater than 0 and less then 10,00,000 before proceeding."
      )
      return
    }

    setShowConfirmationModal(true)
  }

  const formatNumberWithCommas = (value: string) => {
    if (!value || value === "0" || isNaN(Number(value))) {
      return ""
    }
    return Number(value).toLocaleString("en-IN")
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, "")

    if (value.startsWith("0")) {
      value = value.slice(1)
    }


    setEnteredAmount(value)
  }

  const handleConfirmTransfer = async () => {
    try {
      const formData = new FormData()

      formData.append("accountManagerId", managerId)
      formData.append("resellerId", transferTo)
      formData.append("jemsCount", String(enteredAmount))
      formData.append("basePrice", basePrice)
      formData.append("discountedPercentage", discount)
      formData.append("conversionFactor", conversionFactor)

      if (uploadedFile) {
        formData.append("invoice", uploadedFile, uploadedFile.name)
      }

      setShowConfirmationModal(false)

      const response = await transferJems(formData)
      if (response.status === 200) {
        closeModal()
      }
    } catch (error: any) {
      console.error("Transfer failed:", error.response?.data || error.message)
    }
  }

  const handleCancelConfirmation = () => {
    setShowConfirmationModal(false) // Close confirmation modal without transfer
  }

  return (
    <>
      <Modal isOpen={modalOpen} toggle={closeModal} centered size="lg">
        <ModalHeader toggle={closeModal} className="text-center">
          Transfer Jems
        </ModalHeader>
        <ModalBody className="pb-sm-5">
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col sm={12} md={6} className="mt-2">
                  <Label for="transferTo">Transfer to</Label>
                  <Input
                    placeholder="Enter ID"
                    value={transferTo}
                    onChange={e => setTransferTo(e.target.value)}
                    required
                    style={{ backgroundColor: "#f0f0f0" }}
                    disabled
                    suffix={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          src={selectedReseller.profile_pic}
                          size="small"
                        />
                        <span style={{ marginLeft: 8 }}>
                          {selectedReseller.name}
                        </span>
                      </div>
                    }
                  />
                </Col>
                <Col sm={12} md={6} className="mt-2">
                  <Label for="basePrice">Base Price</Label>

                  <p
                    style={{
                      backgroundColor: "#f0f0f0",
                      padding: "4px 11px",
                      borderRadius: "6px",
                      border: "1px solid #d9d9d9",
                      color: "rgba(0, 0, 0, 0.88)",
                      fontSize: "14px",
                    }}
                  >
                    {basePrice}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={6} className="mt-2">
                  <Label for="amount">Amount</Label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      border: "1px solid #ced4da",
                      borderRadius: "6px",
                    }}
                  >
                    <img
                      src="https://saz.myjosh.in/stream-az/prod-ugc-contents/static-images/categories/coin.png"
                      alt="coin-icon"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "8px",
                      }}
                    />
                    <Input
                      placeholder="1"
                      min="1"
                      max="1000000"
                      type="text"
                      required
                      style={{
                        backgroundColor: "#fff",
                        border: "none",
                        flex: 1,
                      }}
                      value={formatNumberWithCommas(enteredAmount)}
                      onChange={handleAmountChange}
                    />
                  </div>
                </Col>

                <Col sm={12} md={6} className="mt-2">
                  <Label for="discount">Discount</Label>
                  <p
                    style={{
                      backgroundColor: "#f0f0f0",
                      padding: "4px 11px",
                      borderRadius: "6px",
                      border: "1px solid #d9d9d9",
                      color: "rgba(0, 0, 0, 0.88)",
                      fontSize: "14px",
                    }}
                  >
                    {discount} %
                  </p>
                </Col>
              </Row>

              <Row>
                <Col
                  sm={12}
                  md={6}
                  className="mt-2"
                  style={{ height: "115px" }}
                >
                  <Label for="upload">Upload</Label>
                  <Upload.Dragger
                    name="file"
                    multiple={false}
                    accept=".jpeg,.png,.pdf,.csv,.doc"
                    style={{
                      border: "1px dashed #ccc",
                      textAlign: "center",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    showUploadList={false}
                    beforeUpload={handleFileChange}
                  >
                    {uploadedFile ? (
                      <p className="text-success" style={{ margin: 0 }}>
                        {uploadedFile.name}
                      </p>
                    ) : (
                      <>
                        <UploadOutlined
                          style={{ fontSize: "24px", color: "#999" }}
                        />
                        <p className="text-danger">Upload</p>
                        <p className="text-muted">Supported formats: .jpeg, .png, .pdf, .csv, .doc</p>
                      </>
                    )}
                  </Upload.Dragger>
                </Col>

                <Col sm={12} md={6} className="mt-2">
                  <Label for="conversionFactor">Conversion Factor</Label>
                  <p
                    style={{
                      backgroundColor: "#f0f0f0",
                      padding: "4px 11px",
                      borderRadius: "6px",
                      border: "1px solid #d9d9d9",
                      color: "rgba(0, 0, 0, 0.88)",
                      fontSize: "14px",
                    }}
                  >
                    {conversionFactor} %
                  </p>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button color="danger" className="px-4" onClick={handleTransferClick}>
            Transfer
          </Button>
          <Button color="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        isOpen={showConfirmationModal}
        toggle={handleCancelConfirmation}
        centered
        size="md"
      >
        <ModalHeader toggle={handleCancelConfirmation} className="text-center">
          Confirm Transfer
        </ModalHeader>
        <ModalBody>
          <Label
            for="username"
            style={{ color: "#929AA6", marginBottom: "1px" }}
          >
            Username
          </Label>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "550",
              color: "#162A48",
              lineHeight: "21.79px",
            }}
          >
            {selectedReseller.name}
          </p>
          <Divider></Divider>
          <Label
            for="useruuid"
            style={{ color: "#929AA6", marginBottom: "1px" }}
          >
            User ID
          </Label>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "550",
              color: "#162A48",
              lineHeight: "21.79px",
            }}
          >
            {selectedReseller.user_uuid}
          </p>
          <Divider></Divider>
          <Label
            for="jemstransfering"
            style={{ color: "#929AA6", marginBottom: "1px" }}
          >
            Jems Transferring
          </Label>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "550",
              color: "#162A48",
              lineHeight: "21.79px",
            }}
          >
            <img
              src="https://saz.myjosh.in/stream-az/prod-ugc-contents/static-images/categories/coin.png"
              alt="coin-icon"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "8px",
              }}
            />
            {formatNumberWithCommas(enteredAmount)}
          </p>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            color="success"
            className="px-4"
            onClick={handleConfirmTransfer}
          >
            Confirm
          </Button>
          <Button color="secondary" onClick={handleCancelConfirmation}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default TransferJemsModal
