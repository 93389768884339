import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { Link } from "react-router-dom"
import { getLocationData } from "appRedux/slices/location"
import SimpleBar from "simplebar-react"
import {
  getAppUserDetailsES,
  resetState,
  getAppUserVideos,
  manageUser,
  updateUser,
  updateUserType,
  updateAppUserCuratedEntities,
  getAppUserDetails,
  tagUser,
  getLogs,
  toggleShoppable,
  toggleAllowJoshLive,
  liveModerateUser,
  updateUserAICaption,
} from "appRedux/slices/appUser"
import toastr from "toastr"
import { capitalize } from "../../util/common"
import moment from "moment"
import _ from "lodash"
import Category from "../../constants/subCategories.json"
import {
  DISPLAY_PERSONA,
  CURATED_GENRE_LIST,
  CURATED_GENRE_LIST_MAPPING,
  CURATED_TYPE_MAPPING,
  USER_TYPE_MAPPING,
  NO_PERMISSION_MESSAGE,
  LANGUAGE_MAPPING,
  creatorProfileV2,
} from "../../constants/uiConstants"
import { Tag, Switch, Tooltip } from "antd"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Table,
  Badge,
  CardTitle,
} from "reactstrap"
import { Button } from "@mui/material"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { USER_DEACTIVATE_REASONS } from "../../constants/uiConstants"
import InlineEdit from "../../components/InlineEdit/inlineEdit"
import ContentList from "components/ContentList/contentList"
import ReactTimeAgo from "react-time-ago"
import NoDataFound from "components/NoDataFound/noDataFound"
import UploadBulkUserForm from "./uploadBulkUserForm"
import CreateAppUserForm from "./createAppUserForm"
import { userContentFilters } from "../../constants/uiConstants"
import Chips from "components/CommonFilter/chips"
import { ButtonGroup, Button as MuiButton } from "@mui/material"
import "./styles.css"
import { ChipsInput } from "containers/ValidatorForm"

const ACTION_MAP = {
  block: "Blocked",
  update_app_user: "Updated",
  unverify: "Un Verified",
  verify: "Verified",
  unblock: "Unblocked",
}

const CELEBRITY_GENERE_LIST_MAPPING = {
  MOTIVATION: "Motivation",
  SPORTS: "Sports",
  FOOD: "Food",
  BOLLYWOOD: "Bollywood",
  TOLLYWOOD: "Tollywood",
}

const startDay = Date.now() - 15 * 24 * 60 * 60 * 1000

class AppUserDetails extends ErrorBoundComponent {
  state = {
    showModal: false,
    action: null,
    isShoppable: false,
    filters: {
      userUuid: this.props.queryParams
        ? this.props.queryParams["userUuid"]
        : this.props.match &&
          this.props.match.params &&
          this.props.match.params["userUuid"]
        ? this.props.match.params["userUuid"]
        : "",
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 12,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      searchType:
        this.props.queryParams && this.props.queryParams["searchType"]
          ? this.props.queryParams["searchType"]
          : null,
      searchText:
        this.props.queryParams && this.props.queryParams["searchText"]
          ? this.props.queryParams["searchText"]
          : null,
      pageType:
        this.props.queryParams && this.props.queryParams["pageType"]
          ? this.props.queryParams["pageType"]
          : "video",
      moderationLevel:
        this.props.queryParams && this.props.queryParams["moderationLevel"]
          ? this.props.queryParams["moderationLevel"]
          : this.props.currentUser.permissions &&
            this.props.currentUser.permissions.includes("VIEW_ACTIVITY")
          ? "2"
          : "",
      langCode:
        this.props.queryParams && this.props.queryParams["langCode"]
          ? this.props.queryParams["langCode"]
          : "",
      orderByField:
        this.props.queryParams && this.props.queryParams["orderByField"]
          ? this.props.queryParams["orderByField"]
          : this.props.currentUser.permissions &&
            this.props.currentUser.permissions.includes("COMMUNITY_MANAGER")
          ? "created_date"
          : "view_count",
      order:
        this.props.queryParams && this.props.queryParams["order"]
          ? this.props.queryParams["order"]
          : "desc",
    },
    showTagUserModal: false,
    categoriesOptions: Category.map(item => ({
      label: item.sub_category,
      value: item.sub_category,
    })),
    displayPersonaOptions: DISPLAY_PERSONA,
    hashtagsOptions: [],
    user_uuidsOptions: [
      {
        label: _.get(this.props, "queryParams.userUuid"),
        value: _.get(this.props, "queryParams.userUuid"),
      },
    ],
    stateMap: null,
    cityMap: null,
    deactivatReason: USER_DEACTIVATE_REASONS,
    userDetails: {},
  }

  inputFile = React.createRef()

  componentDidMount() {
    if (
      !this.props.common.error &&
      ((this.props.queryParams &&
        !_.isEmpty(this.props.queryParams["userUuid"])) ||
        (this.props.match &&
          this.props.match.params &&
          this.props.match.params["userUuid"]))
    ) {
      this.props.dispatch(getLocationData())
      this.props.dispatch(getAppUserDetailsES(_.deepClean(this.state.filters)))
      this.props.dispatch(
        getAppUserVideos(_.deepClean(_.deepClean(this.state.filters)))
      )
      window.addEventListener("keydown", this._keyDownHandler)
    }
    if (this.state.filters.userUuid) {
      this.props.dispatch(
        getLogs({ userId: this.state.filters.userUuid, startDay })
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.common.error) {
      if (prevProps.locationList.length !== this.props.locationList.length) {
        let stateMap = {}
        let cityMap = {}
        this.props.locationList.map(location => {
          location.children.map(
            childrenLocation =>
              (cityMap[childrenLocation.dhTagId] = childrenLocation.name)
          )
          stateMap[location.dhTagId] = location.name
        })
        this.setState({ stateMap, cityMap })
      }

      if (
        this.props?.queryParams?.userUuid !== prevProps?.queryParams?.userUuid
      ) {
        this.setState(
          {
            showModal: false,
            showTagUserModal: false,
            action: null,
            userDetails: {},
            filters: {
              ...this.state.filters,
              userUuid: this.props.queryParams?.userUuid,
            },
          },
          () => {
            this.props.dispatch(
              getAppUserDetailsES(_.deepClean(this.state.filters))
            )
          }
        )
      }

      if (
        !_.isEmpty(this.props.createdUserUUID) &&
        prevProps.createdUserUUID !== this.props.createdUserUUID
      ) {
        this.setState({
          showModal: true,
          action: "createdUserUuid",
        })
      }

      if (
        this.state.userDetails.user_type !== prevState.userDetails.user_type
      ) {
        this._populateCuratedTypeOptions(this.state.userDetails.user_type)
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.userDetails.user_uuid !== state.userDetails.user_uuid) {
      return {
        userDetails: props.userDetails,
      }
    }
  }

  _handleUserIdChange = userId => {
    this.props.history.push(`/appuser/details?userUuid=${userId}`)
    this.setState(
      {
        filters: {
          ...this.state.filters,
          userUuid: userId,
          // searchText: userId,
        },
        userDetails: {},
      },
      () => {
        this.props.dispatch(
          getAppUserDetailsES(_.deepClean(this.state.filters))
        )
        this.props.dispatch(
          getAppUserVideos(_.deepClean(_.deepClean(this.state.filters)))
        )
        this.props.dispatch(
          getLogs({ userId: this.state.filters.userUuid, startDay })
        )
      }
    )
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
      })
    }
  }

  _populateCuratedTypeOptions = jotUserType => {
    let disabled = false,
      curatedTypeOptions = [],
      curatedType
    switch (jotUserType) {
      case "i":
        curatedTypeOptions = [{ label: "Internal", value: "i" }]
        curatedType = "i"
        break
      case "g":
        curatedTypeOptions = [{ label: "Ghost", value: "g" }]
        curatedType = "g"
        break
      case "ib":
        curatedTypeOptions = [{ label: "Internal Brand", value: "ib" }]
        curatedType = "ib"
        break
      case "eb":
        curatedTypeOptions = [{ label: "External Brand", value: "eb" }]
        curatedType = "eb"
        break
      case "e":
        if (
          ["m", "n", "c", "k", "o", "s", "l", "b", "x"].includes(
            this.state.userDetails.curated_type
          )
        ) {
          curatedTypeOptions = [
            {
              label: CURATED_TYPE_MAPPING[this.state.userDetails.curated_type],
              value: this.state.userDetails.curated_type,
            },
          ]
          disabled = true
          curatedType = this.state.userDetails.curated_type
        } else {
          curatedTypeOptions = [
            { label: "UGC", value: "u" },
            { label: "Macro", value: "m" },
            { label: "Nano", value: "n" },
            { label: "Community", value: "c" },
            { label: "OGC", value: "o" },
            { label: "Celebrity/Stars", value: "s" },
            { label: "Micro", value: "l" },
            { label: "Uploader", value: "b" },
            { label: "Churnot Creator", value: "x" },
            {
              label: "Kol",
              value: "k",
              disabled: this.state.userDetails.verified !== true,
            },
          ]
          curatedType = "u"
        }
      default:
        break
    }
    this.setState({
      curatedTypeOptions,
      disabled,
      userDetails: {
        ...this.state.userDetails,
        curated_type: curatedType,
      },
    })
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/appuser/details?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(getAppUserVideos(_.deepClean(this.state.filters)))
      }
    )
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }

    if (
      type === "searchText" ||
      type === "order" ||
      type === "moderationLevel" ||
      type === "langCode"
    ) {
      this.setState({
        filters: newFilters,
      })
    } else {
      this.setState({
        filters: newFilters,
      })
    }
  }

  _searchContent = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/appuser/details?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.setState({ isOpen: false }, () =>
      this.props.dispatch(getAppUserVideos(_.deepClean(filters)))
    )
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: userContentFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/appuser/details?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(getAppUserVideos(_.deepClean(this.state.filters)))
      }
    )
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _closeFilters = () => {
    this._searchContent()
    this.setState({
      showFilters: false,
    })
  }

  _renderVideos = () => {
    return this.props.userVideos.length > 0 ? (
      <>
        <Chips
          showFilters={this.state.showFilters}
          // showAdvancedFilters={this.state.showAdvancedFilters}
          filters={this.state.filters}
          search={this._closeFilters}
          removeFilter={this._removeFilter}
          handleFilterChange={this._handleFilterChange}
          type="user_content"
          toggleFilters={this._toggleFilters}
          // toggleAdvancedFilters={this._toggleAdvancedFilters}
        />
        <ContentList
          contentList={[...this.props.userVideos]}
          currentPage={this.state.filters["currentPage"]}
          pageSize={this.state.filters["pageSize"]}
          currentUser={this.props.currentUser}
          total={this.props.totalUserVideos}
          onPageChange={this._onPageChange}
          contentViewChange={() => {}}
        />
      </>
    ) : (
      <NoDataFound message="No Data To Display" />
    )
  }

  _renderChangelog = () => {
    return (
      <React.Fragment>
        <div>
          <Container fluid>
            <Row>
              {this.props.logs.length > 0 ? (
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      <table className="TFtable table-nowrap align-middle table-borderless">
                        {this.props.logs.map((log, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {log.action && (
                                  <div>
                                    Profile status changed to{" "}
                                    <b>{ACTION_MAP[log.action] || "changes"}</b>
                                  </div>
                                )}
                                {log.content_uuid && (
                                  <div>
                                    Created video with video Id{" "}
                                    <Link
                                      to={`/content/manage?contentUUID=${log.content_uuid}`}
                                    >
                                      {log.content_uuid}
                                    </Link>
                                  </div>
                                )}
                                <div className="text-muted">
                                  {(log.createdDate || log.created_date) && (
                                    <ReactTimeAgo
                                      date={
                                        Number.isNaN(
                                          log.createdDate || log.created_date
                                        )
                                          ? Date.parse(
                                              log.createdDate ||
                                                log.created_date
                                            )
                                          : log.createdDate || log.created_date
                                      }
                                      locale="en-US"
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </table>
                    </div>
                  </div>
                </Col>
              ) : (
                <NoDataFound message="No Data To Display" />
              )}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
    // }
  }

  _renderStatistics = () => {
    return null
  }

  _renderPages = () => {
    switch (this.state.filters.pageType) {
      case "video":
        return this._renderVideos()
      case "changelog":
        return this._renderChangelog()
      case "statistics":
        return this._renderStatistics()
      default:
        break
    }
  }

  _performManageUserAction = action => {
    if (!this._isPermitted(action)) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    let verified = this.state.userDetails.verified
    let moderation_level = this.state.userDetails.moderation_level
    let legally_disabled
    switch (action) {
      case "set-verified":
        verified = true
        break
      case "set-unverified":
        verified = false
        break
      case "block":
        moderation_level = -1
        break
      case "unblock":
        moderation_level = 1
        break
      case "legal-block":
        action = "block"
        moderation_level = -2
        legally_disabled = true
        break
    }

    this.setState(
      {
        userDetails: {
          ...this.state.userDetails,
          verified,
          moderation_level,
        },
      },
      () =>
        this.props.dispatch(
          manageUser({
            action,
            userUuid: this.state.userDetails.user_uuid,
            description: this.state.reject_reason,
            legally_disabled,
          })
        )
    )
  }

  _updateUserType = (field, value) => {
    this.props.dispatch(
      updateUserType({
        user_type: value,
        user_uuid: this.state.userDetails.user_uuid,
      })
    )
  }

  _updateUser = (field, value) => {
    const data = new FormData()
    if (field === "profile") {
      data.append("file", value)
    }
    if (field == "account_type") {
      const userData = {
        account_type: value,
        reseller_status: false,
      }
      data.append("details", JSON.stringify(userData))
    } else {
      const userData = {
        [field]: value,
      }
      data.append("details", JSON.stringify(userData))
    }
    this.props.dispatch(
      updateUser({
        data,
        userUuid: this.state.userDetails.user_uuid,
      })
    )
  }

  _updateAllowJoshLiveFlag = value => {
    const users = [
      {
        user_uuid: this.state.userDetails.user_uuid,
        allow_josh_live: value,
      },
    ]
    this.props.dispatch(
      toggleAllowJoshLive({
        users,
      })
    )
  }

  _updateAiCaption = (field, value) => {
    const user = {
      user_uuid: this.state.userDetails.user_uuid,
      [field]: value,
    }
    this.props.dispatch(updateUserAICaption(user))
  }

  _updateShoppableFlag = value => {
    const users = [
      {
        user_uuid: this.state.userDetails.user_uuid,
        shoppable: value,
      },
    ]
    this.props.dispatch(
      toggleShoppable({
        users,
      })
    )
  }

  _isPermitted = field => {
    const { permissions } = this.props.currentUser
    if (permissions?.includes("EDIT_USER_DETAILS")) {
      return true
    }
    return false
  }

  _isPhoneUserBlockPermitted = field => {
    const { permissions } = this.props.currentUser
    if (permissions?.includes("PHONE_USER_BLOCK")) {
      return true
    }
    return false
  }

  _isJoshLivePermitted = field => {
    const { permissions } = this.props.currentUser
    if (permissions?.includes("EDIT_JOSH_LIVE")) {
      return true
    }
    return false
  }

  _isGiftEnabledPermitted = field => {
    const { permissions } = this.props.currentUser
    if (permissions?.includes("EDIT_GIFT_ENABLED")) {
      return true
    }
    return false
  }

  _isCreatorPayoutPermitted = field => {
    const { permissions } = this.props.currentUser
    if (permissions?.includes("EDIT_CREATOR_PAYOUT")) {
      return true
    }
    return false
  }

  _isAllowContentInfoPermitted = field => {
    const { permissions } = this.props.currentUser
    if (permissions?.includes("EDIT_ALLOW_CONTENT_INFO")) {
      return true
    }
    return false
  }

  _isAllowAICaptionPermitted = field => {
    const { permissions } = this.props.currentUser
    if (permissions?.includes("EDIT_AI_CAPTION")) {
      return true
    }
    return false
  }

  _deactivateUserModal = () => {
    let radioList = this.state.deactivatReason

    return (
      <>
        <Card className="shadow-lg border-2 mt-2">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="text-dark">De Activate Reasons</div>
              <div
                className="avatar-xs"
                role="button"
                onClick={() => this.setState({ showReasonModal: false })}
              >
                <div className="avatar-title rounded-circle bg-light">
                  <span
                    className={
                      "avatar-title rounded-circle bg-light bg-" +
                      "secondary" +
                      " text-dark" +
                      " font-size-16" +
                      " font-weight-semibold"
                    }
                  >
                    <i className="fas fa-times-circle" />
                  </span>
                </div>
              </div>
            </div>
            {radioList.map((reason, index) => {
              return (
                <div className="form-check form-radio-outline form-radio-primary mb-3">
                  <input
                    type="radio"
                    id={`radio${index}`}
                    name="customRadiooutlinecolor1"
                    className="form-check-input"
                    value={reason.value}
                    onChange={e =>
                      this.setState({ reject_reason: e.target.value })
                    }
                  />
                  <label className="form-check-label" htmlFor={`radio${index}`}>
                    {reason.label}
                  </label>
                </div>
              )
            })}

            <div className="d-flex justify-content-end">
              <div
                onClick={() =>
                  this.setState({ showReasonModal: false }, () =>
                    this._performManageUserAction("block")
                  )
                }
                role="button"
                className="btn btn-danger d-flex mx-1"
              >
                De Activate
              </div>
            </div>
          </CardBody>
        </Card>
      </>
    )
  }

  _updateCuratedEntities = (field, value) => {
    let data = value
    if (field === "curated_genre") {
      data = value.join(" ")
    }

    this.props.dispatch(
      updateAppUserCuratedEntities({
        userUuid: this.state.userDetails.user_uuid,
        data: {
          [field]: data,
        },
      })
    )
  }

  _handleTagUser = (field, value) => {
    let payload = {
      [field]: value,
    }
    this.props.dispatch(
      tagUser({
        ...payload,
        user_uuids: [this.state.userDetails.user_uuid],
      })
    )
  }

  _onProfileUpload = e => {
    const file = URL.createObjectURL(e.target.files[0])
    this.setState(
      {
        userDetails: {
          ...this.state.userDetails,
          profile_pic: file,
        },
      },
      () => {
        this._updateUser("profile", e.target.files[0])
      }
    )
  }

  _isPhoneNumberValid(phoneNumber) {
    const phoneRegex = /^(91|0)?[6-9][0-9]{9}$/ // Indian phone number pattern
    console.log("test", phoneNumber, phoneRegex.test(phoneNumber))

    return phoneRegex.test(phoneNumber)
  }

  _renderSocial() {
    const accounts = this.state.userDetails.tagged_social_account
    return (
      !_.isEmpty(accounts) && (
        <>
          <div className="border-top border-2 mt-4"></div>
          <Row>
            <p className="text-muted mt-1">SOCIAL</p>
            {accounts.instagram_info && (
              <div className="d-flex align-items-center">
                <div className="avatar-xs me-3">
                  <div className="avatar-title rounded-circle bg-light">
                    <span
                      className={
                        "avatar-title rounded-circle bg-light bg-" +
                        "secondary text-black text-muted" +
                        " font-size-16"
                      }
                    >
                      <i className="fab fa-instagram"></i>
                    </span>
                  </div>
                </div>
                <div>{accounts.instagram_info.handle}</div>
              </div>
            )}

            {accounts.youtube_info && (
              <div className="d-flex align-items-center">
                <div className="avatar-xs me-3">
                  <div className="avatar-title rounded-circle bg-light">
                    <span
                      className={
                        "avatar-title rounded-circle bg-light bg-" +
                        "secondary text-black text-muted" +
                        " font-size-16"
                      }
                    >
                      <i className="fab fa-youtube" />
                    </span>
                  </div>
                </div>
                <div>{accounts.youtube_info.handle}</div>
              </div>
            )}
            {accounts.website_info && (
              <div className="d-flex align-items-center">
                <div className="avatar-xs me-3">
                  <div className="avatar-title rounded-circle bg-light">
                    <span
                      className={
                        "avatar-title rounded-circle bg-light bg-" +
                        "secondary text-black text-muted" +
                        " font-size-16"
                      }
                    >
                      <i className="fas fa-globe" />
                    </span>
                  </div>
                </div>
                <div>{accounts.website_info.link}</div>
              </div>
            )}
          </Row>
        </>
      )
    )
  }

  _liveModeration = action => {
    let liveBlocked = false
    let data = {
      userUuid: this.props.userDetails.user_uuid,
      blockUser: false,
    }
    if (action === "block") {
      data.blockUser = true
      liveBlocked = true
    }

    this.setState(
      {
        userDetails: {
          ...this.state.userDetails,
          liveBlocked,
        },
      },
      () => this.props.dispatch(liveModerateUser(data))
    )
  }

  _getForm = () => {
    return (
      <Card>
        <CardTitle className="p-3 pb-0">
          {this.state.formAction === "createUser"
            ? "Create User"
            : "Upload User"}
        </CardTitle>
        <CardBody style={{ maxHeight: "75vh", overflowY: "auto" }}>
          {this.state.formAction === "upload" && (
            <UploadBulkUserForm
              onCancel={() =>
                this.setState({ showForm: false, formAction: null })
              }
              isPhoneUserBlockPermitted={this._isPhoneUserBlockPermitted()}
            />
          )}
          {this.state.formAction === "createUser" && (
            <CreateAppUserForm
              onCancel={() =>
                this.setState({ showForm: false, formAction: null })
              }
            />
          )}
        </CardBody>
      </Card>
    )
  }

  render() {
    const { userDetails } = this.state
    return (
      <Auxiliary
        loading={this.props.loading || this.props.loadingUser}
        error={_.get(this.props, "common.error")}
      >
        <Row className="mb-4">
          <Col lg="4" className="p-0">
            <div>
              <input
                className="form-control"
                type="text"
                onChange={e => this._handleUserIdChange(e.target.value)}
                value={this.state.filters.userUuid}
                placeholder="User ID"
              />
            </div>
          </Col>
          <Col lg="8">
            <div className="d-flex justify-content-end">
              <div className="text-sm-end" style={{ paddingBottom: "15px" }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    if (!this._isPermitted()) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.setState(
                      {
                        showForm: false,
                      },
                      () =>
                        this.setState({
                          formAction: "createUser",
                          showForm: true,
                          currentData: {},
                        })
                    )
                  }}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create
                </Button>
              </div>
              <div className="text-sm-end" style={{ paddingBottom: "15px" }}>
                <Button
                  color="primary"
                  variant="contained"
                  className="mx-2"
                  onClick={() => {
                    if (!this._isPermitted()) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.setState(
                      {
                        showForm: false,
                      },
                      () =>
                        this.setState({
                          formAction: "upload",
                          showForm: true,
                          currentData: {},
                        })
                    )
                  }}
                >
                  <i className="bx bx-upload me-1" />
                  Upload
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        {!_.isEmpty(userDetails) && (
          <Container fluid className="mx-1">
            {!this.state.showForm && (
              <Row>
                <Col xl="4" sm="6" md="5" lg="5" className="p-0">
                  <SimpleBar
                    style={{ height: "85vh", backgroundColor: "white" }}
                  >
                    <div className="h-100">
                      <CardBody className="pt-0 h-100">
                        <Row className="mt-4 align-items-center">
                          <Col xl="4" md="6" lg="6" sm="6">
                            <div className="d-flex justify-content-center">
                              <div
                                style={{ position: "relative" }}
                                className={
                                  this._isPermitted("profile") &&
                                  "profile-container"
                                }
                              >
                                <img
                                  className="rounded-circle avatar-lg img-fluid profile-pic"
                                  src={
                                    userDetails.profile_pic ||
                                    "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
                                  }
                                  alt=""
                                />
                                {this._isPermitted("profile") && (
                                  <div
                                    className="upload"
                                    onClick={() =>
                                      this.inputFile.current.click()
                                    }
                                  >
                                    <span
                                      style={{ fontSize: "2rem" }}
                                      role="button"
                                    >
                                      <i className="mdi mdi-cloud-upload">
                                        <input
                                          type="file"
                                          // accept="image/jpeg"
                                          id="file"
                                          ref={this.inputFile}
                                          style={{ display: "none" }}
                                          onChange={this._onProfileUpload}
                                        />
                                      </i>
                                    </span>
                                  </div>
                                )}
                                {userDetails.verified && (
                                  <span className="verified-icon">
                                    <i className="mdi mdi-check-decagram"></i>
                                  </span>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xl="8" md="6" lg="6" sm="6">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h5 className="font-size-17 mb-1 text-dark">
                                  {this._isPermitted("name") ? (
                                    <InlineEdit
                                      text={userDetails.name}
                                      onSetText={value =>
                                        this.setState({
                                          userDetails: {
                                            ...this.state.userDetails,
                                            name: value,
                                          },
                                        })
                                      }
                                      onSubmit={value =>
                                        this._updateUser("name", value)
                                      }
                                      showHover={this._isPermitted("name")}
                                      type="textfield"
                                    />
                                  ) : (
                                    userDetails.name
                                  )}
                                </h5>
                                <p className="font-size-15 text-muted mb-4 d-flex">
                                  {`@`}
                                  {this._isPermitted("name") ? (
                                    <InlineEdit
                                      text={userDetails.handle}
                                      onSetText={value =>
                                        this.setState({
                                          userDetails: {
                                            ...this.state.userDetails,
                                            handle: value,
                                          },
                                        })
                                      }
                                      showHover={this._isPermitted("handle")}
                                      onSubmit={value =>
                                        this._updateUser("username", value)
                                      }
                                      type="textfield"
                                    />
                                  ) : (
                                    userDetails.handle
                                  )}
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div className="border-top border-2 mt-4"></div>
                        <Row>
                          <p className="text-muted mt-1">MODERATION</p>
                          <ButtonGroup className="mx-2">
                            <MuiButton
                              disabled={
                                this.state.showReasonModal ||
                                this.state.userDetails.moderation_level ===
                                  -1 ||
                                this.state.userDetails.moderation_level === -2
                              }
                              onClick={() =>
                                this.setState({ showReasonModal: true })
                              }
                            >
                              Block
                            </MuiButton>
                            <MuiButton
                              disabled={
                                this.state.showReasonModal ||
                                this.state.userDetails.moderation_level === -2
                              }
                              onClick={() =>
                                this._performManageUserAction("legal-block")
                              }
                            >
                              Legal Block
                            </MuiButton>
                            <MuiButton
                              onClick={() =>
                                this._performManageUserAction("unblock")
                              }
                              disabled={
                                this.state.userDetails.moderation_level === -2
                                  ? this.props.currentUser?.permissions?.includes(
                                      "LEGAL_ACTION"
                                    )
                                    ? false
                                    : true
                                  : this.state.userDetails.moderation_level !==
                                    -1
                              }
                            >
                              Un-Block
                            </MuiButton>
                          </ButtonGroup>
                          <ButtonGroup className="mx-2">
                            <MuiButton
                              onClick={() =>
                                this._performManageUserAction("set-unverified")
                              }
                              disabled={!this.state.userDetails.verified}
                            >
                              Un-Verify
                            </MuiButton>
                            <MuiButton
                              onClick={() =>
                                this._performManageUserAction("set-verified")
                              }
                              disabled={
                                this.state.userDetails.verified
                                  ? this.state.userDetails.verified
                                  : this.state.userDetails.moderation_level ===
                                    -2
                                  ? this.props.currentUser?.permissions?.includes(
                                      "LEGAL_ACTION"
                                    )
                                    ? false
                                    : true
                                  : false
                              }
                            >
                              Verify
                            </MuiButton>
                          </ButtonGroup>
                        </Row>
                        <div className="border-top border-2 mt-4"></div>
                        <Row>
                          <p className="text-muted mt-1">LIVE MODERATION</p>
                          <ButtonGroup className="mx-2">
                            <MuiButton
                              onClick={() => {
                                if (
                                  !this.props.currentUser?.permissions?.includes(
                                    "EDIT_BROWSE_ROOM"
                                  )
                                ) {
                                  toastr.error(NO_PERMISSION_MESSAGE)
                                  return
                                }
                                this._liveModeration("block")
                              }}
                              disabled={this.state.userDetails.liveBlocked}
                            >
                              Block
                            </MuiButton>
                            <MuiButton
                              onClick={() => {
                                if (
                                  !this.props.currentUser?.permissions?.includes(
                                    "EDIT_BROWSE_ROOM"
                                  )
                                ) {
                                  toastr.error(NO_PERMISSION_MESSAGE)
                                  return
                                }
                                this._liveModeration("un-block")
                              }}
                              disabled={!this.state.userDetails.liveBlocked}
                            >
                              Un-Block
                            </MuiButton>
                          </ButtonGroup>
                        </Row>
                        <div className="border-top border-2 mt-4"></div>
                        <Row>
                          <p className="text-muted mt-1">TAGGED VIDEOS</p>
                          {/* <Col sm={12} md={6}> */}
                          <ChipsInput
                            // label={"Tagged Videos"}
                            placeholderLabel="Tagged Videos"
                            field={"tagged_videos"}
                            onChange={e => {
                              this.setState({
                                userDetails: {
                                  ...this.state.userDetails,
                                  tagged_videos: e,
                                },
                              })
                            }}
                            defaultValue={
                              this.state.userDetails.tagged_videos || []
                            }
                            className="creator-form"
                          />
                          <div className="d-flex justify-content-end">
                            <MuiButton
                              variant="outlined"
                              onClick={e => {
                                if (!this._isPermitted()) {
                                  toastr.error(NO_PERMISSION_MESSAGE)
                                  return
                                }
                                e.stopPropagation(),
                                  e.preventDefault(),
                                  this._updateUser(
                                    "tagged_videos",
                                    this.state.userDetails.tagged_videos
                                  )
                              }}
                            >
                              Update
                            </MuiButton>
                          </div>
                        </Row>
                        {this.state.showReasonModal &&
                          this._deactivateUserModal()}
                        <div className="border-top border-2 mt-4"></div>
                        <Row>
                          <p className="text-muted mt-1">ABOUT</p>

                          <div>
                            <Table className="mb-0 table-wrap table-striped">
                              <tbody>
                                <tr className="text-start">
                                  <th scope="row" style={{ width: "40%" }}>
                                    Bio
                                  </th>
                                  <td>
                                    <p>
                                      {this._isPermitted("bio_data") ? (
                                        <InlineEdit
                                          text={
                                            userDetails.bio_data?.replace(
                                              /\n/g,
                                              ""
                                            )
                                              ? userDetails.bio_data
                                              : "N/A"
                                          }
                                          onSetText={value =>
                                            this.setState({
                                              userDetails: {
                                                ...this.state.userDetails,
                                                bio_data: value,
                                              },
                                            })
                                          }
                                          showHover={this._isPermitted(
                                            "bio_data"
                                          )}
                                          onSubmit={value =>
                                            this._updateUser("bio_data", value)
                                          }
                                          type="textarea"
                                        />
                                      ) : userDetails.bio_data ? (
                                        userDetails.bio_data
                                      ) : (
                                        "N/A"
                                      )}
                                    </p>
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Gender</th>
                                  <td>
                                    {this._isPermitted("gender") ? (
                                      <InlineEdit
                                        text={
                                          userDetails.gender === "-1" ||
                                          userDetails.gender === "others"
                                            ? "N/A"
                                            : userDetails.gender === "1" ||
                                              userDetails.gender === "male"
                                            ? "Male"
                                            : "Female"
                                        }
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              gender: value,
                                            },
                                          })
                                        }
                                        onSubmit={value =>
                                          this._updateUser("gender", value)
                                        }
                                        showHover={this._isPermitted("gender")}
                                        type="single-select"
                                        options={[
                                          { label: "Male", value: "male" },
                                          { label: "Female", value: "female" },
                                          { label: "Others", value: "others" },
                                        ]}
                                      />
                                    ) : userDetails.gender === "-1" ||
                                      userDetails.gender === "others" ? (
                                      "N/A"
                                    ) : userDetails.gender === "1" ||
                                      userDetails.gender === "male" ? (
                                      "Male"
                                    ) : (
                                      "Female"
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Creator Profile V2</th>
                                  <td>
                                    {this._isPermitted("creator_profile_v2") ? (
                                      <InlineEdit
                                        text={
                                          (userDetails.creator_profile_v2 &&
                                            userDetails.creator_profile_v2
                                              .toUpperCase()
                                              .split("_")
                                              .join(" ")) ||
                                          "N/A"
                                        }
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              creator_profile_v2: value,
                                            },
                                          })
                                        }
                                        onSubmit={value =>
                                          this._updateUser(
                                            "creator_profile_v2",
                                            value
                                          )
                                        }
                                        showHover={this._isPermitted(
                                          "creator_profile_v2"
                                        )}
                                        type="single-select"
                                        options={creatorProfileV2}
                                      />
                                    ) : userDetails.creator_profile_v2 ? (
                                      userDetails.creator_profile_v2
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Skip Masking</th>
                                  <td>
                                    {this._isPermitted("skip_masking") ? (
                                      <InlineEdit
                                        text={
                                          userDetails.skip_masking === "Y"
                                            ? "Yes"
                                            : "No"
                                        }
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              skip_masking: value,
                                            },
                                          })
                                        }
                                        onSubmit={value =>
                                          this._updateUser(
                                            "skip_masking",
                                            value
                                          )
                                        }
                                        showHover={this._isPermitted(
                                          "skip_masking"
                                        )}
                                        type="single-select"
                                        options={[
                                          { label: "Yes", value: "Y" },
                                          { label: "No", value: "N" },
                                        ]}
                                      />
                                    ) : userDetails.skip_masking === "Y" ? (
                                      "Yes"
                                    ) : (
                                      "No"
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Skip Moderation</th>
                                  <td>
                                    {this._isPermitted("skip_moderation") ? (
                                      <InlineEdit
                                        text={
                                          userDetails.skip_moderation === "Y"
                                            ? "Yes"
                                            : "No"
                                        }
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              skip_moderation: value,
                                            },
                                          })
                                        }
                                        showHover={this._isPermitted(
                                          "skip_moderation"
                                        )}
                                        onSubmit={value =>
                                          this._updateUser(
                                            "skip_moderation",
                                            value
                                          )
                                        }
                                        type="single-select"
                                        options={[
                                          { label: "Yes", value: "Y" },
                                          { label: "No", value: "N" },
                                        ]}
                                      />
                                    ) : userDetails.skip_moderation === "Y" ? (
                                      "Yes"
                                    ) : (
                                      "No"
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Skip Duplicate</th>
                                  <td>
                                    {this._isPermitted("skip_duplicate") ? (
                                      <InlineEdit
                                        text={
                                          userDetails.skip_duplicate === "Y"
                                            ? "Yes"
                                            : "No"
                                        }
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              skip_duplicate: value,
                                            },
                                          })
                                        }
                                        showHover={this._isPermitted(
                                          "skip_duplicate"
                                        )}
                                        onSubmit={value =>
                                          this._updateUser(
                                            "skip_duplicate",
                                            value
                                          )
                                        }
                                        type="single-select"
                                        options={[
                                          { label: "Yes", value: "Y" },
                                          { label: "No", value: "N" },
                                        ]}
                                      />
                                    ) : userDetails.skip_duplicate === "Y" ? (
                                      "Yes"
                                    ) : (
                                      "No"
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Paid</th>
                                  <td>
                                    {this._isPermitted("remunerate") ? (
                                      <InlineEdit
                                        text={
                                          userDetails.remunerate === "Y"
                                            ? "Yes"
                                            : "No"
                                        }
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              remunerate: value,
                                            },
                                          })
                                        }
                                        showHover={this._isPermitted(
                                          "remunerate"
                                        )}
                                        onSubmit={value =>
                                          this._updateUser("remunerate", value)
                                        }
                                        type="single-select"
                                        options={[
                                          { label: "Yes", value: "Y" },
                                          { label: "No", value: "N" },
                                        ]}
                                      />
                                    ) : userDetails.remunerate === "Y" ? (
                                      "Yes"
                                    ) : (
                                      "No"
                                    )}
                                  </td>
                                </tr>

                                <tr className="text-start">
                                  <th scope="row">Celebrity Name</th>
                                  <td>
                                    {this._isPermitted("celebrity_name") ? (
                                      <InlineEdit
                                        text={
                                          userDetails.celebrity_name || "N/A"
                                        }
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              celebrity_name: value,
                                            },
                                          })
                                        }
                                        onSubmit={value =>
                                          this._updateUser(
                                            "celebrity_name",
                                            value
                                          )
                                        }
                                        showHover={this._isPermitted(
                                          "celebrity_name"
                                        )}
                                        type="textfield"
                                      />
                                    ) : (
                                      userDetails.celebrity_name || "N/A"
                                    )}
                                  </td>
                                </tr>

                                <tr className="text-start">
                                  <th scope="row">User Page Type</th>
                                  <td>
                                    {this._isPermitted("user_page_type") ? (
                                      <InlineEdit
                                        text={
                                          userDetails.user_page_type ===
                                          "FAN_PAGE"
                                            ? "Fan Page"
                                            : userDetails.user_page_type ===
                                              "CELEBRITY"
                                            ? "Celebrity"
                                            : "N/A"
                                        }
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              user_page_type: value,
                                            },
                                          })
                                        }
                                        showHover={this._isPermitted(
                                          "user_page_type"
                                        )}
                                        onSubmit={value =>
                                          this._updateUser(
                                            "user_page_type",
                                            value
                                          )
                                        }
                                        type="single-select"
                                        options={[
                                          {
                                            label: "Celebrity",
                                            value: "CELEBRITY",
                                          },
                                          {
                                            label: "Fan Page",
                                            value: "FAN_PAGE",
                                          },
                                        ]}
                                      />
                                    ) : userDetails.user_page_type ===
                                      "FAN_PAGE" ? (
                                      "Fan Page"
                                    ) : userDetails.user_page_type ===
                                      "CELEBRITY" ? (
                                      "Celebrity"
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Celebrity Genre </th>
                                  <td>
                                    {this._isPermitted("celebrity_genre") ? (
                                      <InlineEdit
                                        component={
                                          userDetails.celebrity_genre
                                            ? // userDetails.celebrity_genre.length > 0
                                              userDetails.celebrity_genre.map(
                                                genre =>
                                                  CELEBRITY_GENERE_LIST_MAPPING[
                                                    genre
                                                  ] && (
                                                    <Badge
                                                      pill
                                                      className="badge-soft-primary m-1"
                                                    >
                                                      <div className="font-size-12 p-1">
                                                        {
                                                          CELEBRITY_GENERE_LIST_MAPPING[
                                                            genre
                                                          ]
                                                        }
                                                      </div>
                                                    </Badge>
                                                  )
                                              )
                                            : "N/A"
                                        }
                                        text={userDetails.celebrity_genre}
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              celebrity_genre: value,
                                            },
                                          })
                                        }
                                        showHover={this._isPermitted(
                                          "celebrity_genre"
                                        )}
                                        onSubmit={value =>
                                          this._updateUser(
                                            "celebrity_genre",
                                            value
                                          )
                                        }
                                        type="multi-select"
                                        options={Object.keys(
                                          CELEBRITY_GENERE_LIST_MAPPING
                                        ).map(genere => {
                                          return {
                                            label:
                                              CELEBRITY_GENERE_LIST_MAPPING[
                                                genere
                                              ],
                                            value: genere,
                                          }
                                        })}
                                      />
                                    ) : userDetails.celebrity_genre ? (
                                      // userDetails.celebrity_genre.length > 0
                                      userDetails.celebrity_genre.map(
                                        genre =>
                                          CELEBRITY_GENERE_LIST_MAPPING[
                                            genre
                                          ] && (
                                            <Badge
                                              pill
                                              className="badge-soft-primary m-1"
                                            >
                                              <div className="font-size-12 p-1">
                                                {
                                                  CELEBRITY_GENERE_LIST_MAPPING[
                                                    genre
                                                  ]
                                                }
                                              </div>
                                            </Badge>
                                          )
                                      )
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Created On</th>
                                  <td>
                                    {moment
                                      .tz(
                                        userDetails.created_time,
                                        "Asia/Calcutta"
                                      )
                                      .format("MMMM Do, YYYY - hh:mm A")}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">
                                    Website Link
                                    {userDetails?.website_link_status ===
                                      "VERIFIED" && (
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "1rem",
                                        }}
                                      >
                                        <i className="mdi mdi-check-decagram"></i>
                                      </span>
                                    )}
                                    {userDetails?.website_link_status ===
                                      "REJECTED" && (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "1rem",
                                        }}
                                      >
                                        <i className="mdi mdi-close-octagon"></i>
                                      </span>
                                    )}
                                  </th>
                                  <td
                                    className="d-flex justify-content-between"
                                    style={{ lineBreak: "anywhere" }}
                                  >
                                    {this._isPermitted("website_link") ? (
                                      <InlineEdit
                                        text={userDetails.website_link || "N/A"}
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              website_link: value,
                                            },
                                          })
                                        }
                                        onSubmit={value =>
                                          this._updateUser(
                                            "website_link",
                                            value
                                          )
                                        }
                                        showHover={this._isPermitted(
                                          "website_link"
                                        )}
                                        type="textfield"
                                      />
                                    ) : (
                                      userDetails.website_link || "N/A"
                                    )}
                                    {userDetails.website_link && (
                                      <div className="d-flex">
                                        {userDetails?.website_link_status !==
                                          "VERIFIED" && (
                                          <div
                                            role="button"
                                            className="text-success"
                                          >
                                            <Tooltip title="Approve Website Link">
                                              <i
                                                className="bx bxs-check-circle me-1 font-size-22 fw-bold"
                                                id="edittooltip"
                                                onClick={e => {
                                                  if (
                                                    !this.props.currentUser?.permissions?.includes(
                                                      "VERIFY_WEBSITE_LINK"
                                                    )
                                                  ) {
                                                    toastr.error(
                                                      NO_PERMISSION_MESSAGE
                                                    )
                                                    return
                                                  }
                                                  e.stopPropagation(),
                                                    e.preventDefault(),
                                                    this.setState({
                                                      userDetails: {
                                                        ...this.state
                                                          .userDetails,
                                                        website_link_status:
                                                          "VERIFIED",
                                                      },
                                                    })
                                                  this._updateUser(
                                                    "website_link_status",
                                                    "VERIFIED"
                                                  )
                                                }}
                                              ></i>
                                            </Tooltip>
                                          </div>
                                        )}
                                        {userDetails?.website_link_status !==
                                          "REJECTED" && (
                                          <div
                                            role="button"
                                            className="text-danger"
                                          >
                                            <Tooltip title="Reject Website Link">
                                              <i
                                                className="bx bxs-x-circle font-size-22 fw-bold"
                                                id="deletetooltip"
                                                onClick={e => {
                                                  if (
                                                    !this.props.currentUser?.permissions?.includes(
                                                      "VERIFY_WEBSITE_LINK"
                                                    )
                                                  ) {
                                                    toastr.error(
                                                      NO_PERMISSION_MESSAGE
                                                    )
                                                    return
                                                  }
                                                  e.stopPropagation(),
                                                    e.preventDefault(),
                                                    this.setState({
                                                      userDetails: {
                                                        ...this.state
                                                          .userDetails,
                                                        website_link_status:
                                                          "REJECTED",
                                                      },
                                                    })
                                                  this._updateUser(
                                                    "website_link_status",
                                                    "REJECTED"
                                                  )
                                                }}
                                              ></i>
                                            </Tooltip>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </td>
                                </tr>
                                {this.props.currentUser.permissions &&
                                  this.props.currentUser.permissions.includes(
                                    "MANAGE_RESELLERS"
                                  ) && (
                                    <tr className="text-start">
                                      <th scope="row">RESELLER</th>
                                      <td>
                                        <Tooltip
                                          title={
                                            !this._isPhoneNumberValid(
                                              this.props.userDetails.phone_no
                                            )
                                              ? "Phone number is not valid"
                                              : ""
                                          }
                                          placement="top"
                                        >
                                          <td>
                                            {this._isPermitted(
                                              "account_type"
                                            ) ? (
                                              <Switch
                                                defaultChecked={
                                                  userDetails &&
                                                  userDetails.account_type ==
                                                    "RESELLER"
                                                    ? true
                                                    : false
                                                }
                                                disabled={
                                                  userDetails &&
                                                  userDetails.account_type ==
                                                    "RESELLER"
                                                    ? false
                                                    : !this._isPhoneNumberValid(
                                                        this.props.userDetails
                                                          .phone_no
                                                      )
                                                }
                                                onChange={value => {
                                                  this.setState(
                                                    {
                                                      userDetails: {
                                                        ...this.state
                                                          .userDetails,
                                                        account_type: value
                                                          ? "RESELLER"
                                                          : "NONE",
                                                      },
                                                    },
                                                    () =>
                                                      this._updateUser(
                                                        "account_type",
                                                        value
                                                          ? "RESELLER"
                                                          : "EMPTY"
                                                      )
                                                  )
                                                }}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  )}
                              </tbody>
                            </Table>
                          </div>
                        </Row>
                        <div className="border-top border-2 mt-4"></div>
                        <Row className="mx-1">
                          <Col sm="6">
                            <p className="text-muted mt-1">{`MARK SHOPPABLE`}</p>
                            <div className="d-flex align-items-center">
                              <div>
                                {this._isPermitted("shoppable") ? (
                                  <Switch
                                    defaultChecked={
                                      userDetails && userDetails.shoppable
                                        ? true
                                        : false
                                    }
                                    // defaultValue={userDetails && userDetails.shoppable ? true : true}
                                    onChange={value => {
                                      this.setState(
                                        {
                                          userDetails: {
                                            ...this.state.userDetails,
                                            shoppable: value,
                                          },
                                        },
                                        () => this._updateShoppableFlag(value)
                                      )
                                    }}
                                  />
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col sm="6">
                            <p className="text-muted mt-1">{`LIVE / GIFT ENABLE`}</p>
                            <div className="d-flex align-items-center">
                              <div>
                                <Switch
                                  defaultChecked={
                                    userDetails &&
                                    userDetails.allow_josh_live &&
                                    (userDetails.allow_josh_live === "Y" ||
                                      userDetails.allow_josh_live === true) &&
                                    (userDetails.gift_enabled === "Y" ||
                                      userDetails.gift_enabled === true)
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    if (!this._isJoshLivePermitted()) {
                                      toastr.error(NO_PERMISSION_MESSAGE)
                                      return
                                    }
                                    this.setState(
                                      {
                                        userDetails: {
                                          ...this.state.userDetails,
                                          allow_josh_live: value,
                                        },
                                      },
                                      () => this._updateAllowJoshLiveFlag(value)
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div className="border-top border-2 mt-4"></div>
                        <Row className="mx-1">
                          <Col sm="6">
                            <p className="text-muted mt-1">{`ALLOW FOLLOW`}</p>
                            <div className="d-flex align-items-center">
                              <div>
                                <Switch
                                  defaultChecked={
                                    userDetails &&
                                    userDetails.allow_follow &&
                                    (userDetails.allow_follow === "Y" ||
                                      userDetails.allow_follow === true)
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    this.setState(
                                      {
                                        userDetails: {
                                          ...this.state.userDetails,
                                          allow_follow: value ? "Y" : "N",
                                        },
                                      },
                                      () =>
                                        this._updateUser(
                                          "allow_follow",
                                          this.state.userDetails.allow_follow
                                        )
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col sm="6">
                            <p className="text-muted mt-1">{`TIP ENABLED`}</p>
                            <div className="d-flex align-items-center">
                              <div>
                                <Switch
                                  defaultChecked={
                                    userDetails && userDetails.tip_enabled
                                      ? true
                                      : false
                                  }
                                  disabled={
                                    userDetails && userDetails.gift_enabled
                                  }
                                  onChange={value => {
                                    this.setState(
                                      {
                                        userDetails: {
                                          ...this.state.userDetails,
                                          tip_enabled: value,
                                        },
                                      },
                                      () =>
                                        this._updateUser(
                                          "tip_enabled",
                                          this.state.userDetails.tip_enabled
                                        )
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <div className="border-top border-2 mt-4"></div>
                          <Col sm="6">
                            <p className="text-muted mt-1">{`ALLOW CREATOR PAYOUT`}</p>
                            <div className="d-flex align-items-center">
                              <div>
                                <Switch
                                  defaultChecked={
                                    userDetails &&
                                    userDetails.enabled_features &&
                                    userDetails.enabled_features.includes(
                                      "PERFORMANCE_PAYOUT"
                                    )
                                  }
                                  onChange={value => {
                                    if (!this._isCreatorPayoutPermitted()) {
                                      toastr.error(NO_PERMISSION_MESSAGE)
                                      return
                                    }
                                    this.setState(
                                      {
                                        userDetails: {
                                          ...this.state.userDetails,
                                          allow_creator_payout: value,
                                        },
                                      },
                                      () =>
                                        this._updateUser(
                                          "allow_creator_payout",
                                          this.state.userDetails
                                            .allow_creator_payout
                                        )
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col sm="6">
                            <p className="text-muted mt-1">{`ALLOW CONTENT INFO`}</p>
                            <div className="d-flex align-items-center">
                              <div>
                                <Switch
                                  defaultChecked={
                                    userDetails &&
                                    userDetails.allow_content_info
                                  }
                                  onChange={value => {
                                    if (!this._isAllowContentInfoPermitted()) {
                                      toastr.error(NO_PERMISSION_MESSAGE)
                                      return
                                    }
                                    this.setState(
                                      {
                                        userDetails: {
                                          ...this.state.userDetails,
                                          allow_content_info: value,
                                        },
                                      },
                                      () =>
                                        this._updateUser(
                                          "allow_content_info",
                                          this.state.userDetails
                                            .allow_content_info
                                        )
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <div className="border-top border-2 mt-4"></div>
                          <Col sm="6">
                            <p className="text-muted mt-1">{`ENABLE AI CAPTION`}</p>
                            <div className="d-flex align-items-center">
                              <div>
                                <Switch
                                  defaultChecked={
                                    userDetails && userDetails.enable_ai_caption
                                  }
                                  onChange={value => {
                                    if (!this._isAllowAICaptionPermitted()) {
                                      toastr.error(NO_PERMISSION_MESSAGE)
                                      return
                                    }
                                    this.setState(
                                      {
                                        userDetails: {
                                          ...this.state.userDetails,
                                          enable_ai_caption: value,
                                        },
                                      },
                                      () =>
                                        this._updateAiCaption(
                                          "enable_ai_caption",
                                          this.state.userDetails
                                            .enable_ai_caption
                                        )
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col sm="6">
                            <p className="text-muted mt-1">{`AUTO GENERATE AI CAPTION`}</p>
                            <div className="d-flex align-items-center">
                              <div>
                                <Switch
                                  defaultChecked={
                                    userDetails &&
                                    userDetails.auto_generate_ai_caption
                                  }
                                  onChange={value => {
                                    if (!this._isAllowAICaptionPermitted()) {
                                      toastr.error(NO_PERMISSION_MESSAGE)
                                      return
                                    }
                                    this.setState(
                                      {
                                        userDetails: {
                                          ...this.state.userDetails,
                                          auto_generate_ai_caption: value,
                                        },
                                      },
                                      () =>
                                        this._updateAiCaption(
                                          "auto_generate_ai_caption",
                                          this.state.userDetails
                                            .auto_generate_ai_caption
                                        )
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <div className="border-top border-2 mt-4"></div>
                          <Col sm="6">
                            <p className="text-muted mt-1">{`DISABLE GRATIFICATION`}</p>
                            <div className="d-flex align-items-center">
                              <div>
                                <Switch
                                  defaultChecked={
                                    userDetails &&
                                    userDetails.is_gratification_disabled
                                  }
                                  onChange={value => {
                                    this.setState(
                                      {
                                        userDetails: {
                                          ...this.state.userDetails,
                                          is_gratification_disabled: value,
                                        },
                                      },
                                      () =>
                                        this._updateUser(
                                          "is_gratification_disabled",
                                          this.state.userDetails
                                            .is_gratification_disabled
                                        )
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {userDetails.user_type &&
                          ["ib", "eb"].includes(userDetails.user_type) && (
                            <React.Fragment>
                              <div className="border-top border-2 mt-4"></div>
                              <Row>
                                <Col sm="6">
                                  <p className="text-muted mt-1">{`DISPLAY TAG`}</p>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Switch
                                        defaultChecked={
                                          userDetails &&
                                          userDetails.display_tag &&
                                          (userDetails.display_tag === "Y" ||
                                            userDetails.display_tag === true)
                                            ? true
                                            : false
                                        }
                                        onChange={value => {
                                          this.setState(
                                            {
                                              userDetails: {
                                                ...this.state.userDetails,
                                                display_tag: value ? "Y" : "N",
                                              },
                                            },
                                            () =>
                                              this._updateUser(
                                                "display_tag",
                                                this.state.userDetails
                                                  .display_tag
                                              )
                                          )
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <p className="text-muted mt-1">{`DISPLAY CAMERA`}</p>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Switch
                                        defaultChecked={
                                          userDetails &&
                                          userDetails.display_camera &&
                                          (userDetails.display_camera === "Y" ||
                                            userDetails.display_camera === true)
                                            ? true
                                            : false
                                        }
                                        onChange={value => {
                                          this.setState(
                                            {
                                              userDetails: {
                                                ...this.state.userDetails,
                                                display_camera: value
                                                  ? "Y"
                                                  : "N",
                                              },
                                            },
                                            () =>
                                              this._updateUser(
                                                "display_camera",
                                                this.state.userDetails
                                                  .display_camera
                                              )
                                          )
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <div className="border-top border-2 mt-4"></div>
                              <Row>
                                <Col sm="6">
                                  <p className="text-muted mt-1">{`DISPLAY HOME TABS`}</p>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Switch
                                        defaultChecked={
                                          userDetails &&
                                          userDetails.display_home_tabs &&
                                          (userDetails.display_home_tabs ===
                                            "Y" ||
                                            userDetails.display_home_tabs ===
                                              true)
                                            ? true
                                            : false
                                        }
                                        onChange={value => {
                                          this.setState(
                                            {
                                              userDetails: {
                                                ...this.state.userDetails,
                                                display_home_tabs: value
                                                  ? "Y"
                                                  : "N",
                                              },
                                            },
                                            () =>
                                              this._updateUser(
                                                "display_home_tabs",
                                                this.state.userDetails
                                                  .display_home_tabs
                                              )
                                          )
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <p className="text-muted mt-1">{`DISPLAY DESCENDING`}</p>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Switch
                                        defaultChecked={
                                          userDetails &&
                                          userDetails.display_desc &&
                                          (userDetails.display_desc === "Y" ||
                                            userDetails.display_desc === true)
                                            ? true
                                            : false
                                        }
                                        onChange={value => {
                                          this.setState(
                                            {
                                              userDetails: {
                                                ...this.state.userDetails,
                                                display_desc: value ? "Y" : "N",
                                              },
                                            },
                                            () =>
                                              this._updateUser(
                                                "display_desc",
                                                this.state.userDetails
                                                  .display_desc
                                              )
                                          )
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <div className="border-top border-2 mt-4"></div>
                              <Row>
                                <Col sm="6">
                                  <p className="text-muted mt-1">{`DISPLAY  MUTE`}</p>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Switch
                                        defaultChecked={
                                          userDetails &&
                                          userDetails.display_mute &&
                                          (userDetails.display_mute === "Y" ||
                                            userDetails.display_mute === true)
                                            ? true
                                            : false
                                        }
                                        onChange={value => {
                                          this.setState(
                                            {
                                              userDetails: {
                                                ...this.state.userDetails,
                                                display_mute: value ? "Y" : "N",
                                              },
                                            },
                                            () =>
                                              this._updateUser(
                                                "display_mute",
                                                this.state.userDetails
                                                  .display_mute
                                              )
                                          )
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <p className="text-muted mt-1">{`DISPLAY EFFECTS`}</p>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Switch
                                        defaultChecked={
                                          userDetails &&
                                          userDetails.display_effects &&
                                          (userDetails.display_effects ===
                                            "Y" ||
                                            userDetails.display_effects ===
                                              true)
                                            ? true
                                            : false
                                        }
                                        onChange={value => {
                                          this.setState(
                                            {
                                              userDetails: {
                                                ...this.state.userDetails,
                                                display_effects: value
                                                  ? "Y"
                                                  : "N",
                                              },
                                            },
                                            () =>
                                              this._updateUser(
                                                "display_effects",
                                                this.state.userDetails
                                                  .display_effects
                                              )
                                          )
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <div className="border-top border-2 mt-4"></div>
                              <Row>
                                <Col sm="6">
                                  <p className="text-muted mt-1">{`DISPLAY PAGES INFO`}</p>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Switch
                                        defaultChecked={
                                          userDetails &&
                                          userDetails.display_pages_info &&
                                          (userDetails.display_pages_info ===
                                            "Y" ||
                                            userDetails.display_pages_info ===
                                              true)
                                            ? true
                                            : false
                                        }
                                        onChange={value => {
                                          this.setState(
                                            {
                                              userDetails: {
                                                ...this.state.userDetails,
                                                display_pages_info: value
                                                  ? "Y"
                                                  : "N",
                                              },
                                            },
                                            () =>
                                              this._updateUser(
                                                "display_pages_info",
                                                this.state.userDetails
                                                  .display_pages_info
                                              )
                                          )
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <p className="text-muted mt-1">{`ALLOW DOWNLOAD`}</p>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Switch
                                        defaultChecked={
                                          userDetails &&
                                          userDetails.allow_download &&
                                          userDetails.allow_download === "N"
                                            ? false
                                            : true
                                        }
                                        onChange={value => {
                                          this.setState(
                                            {
                                              userDetails: {
                                                ...this.state.userDetails,
                                                allow_download: value
                                                  ? "Y"
                                                  : "N",
                                              },
                                            },
                                            () =>
                                              this._updateUser(
                                                "allow_download",
                                                this.state.userDetails
                                                  .allow_download
                                              )
                                          )
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        <div className="border-top border-2 mt-4"></div>
                        <Row>
                          <p className="text-muted mt-1">{`TYPE & STATUS`}</p>
                          <div className="d-flex align-items-center mx-2">
                            <div className="avatar-xs me-3">
                              <div className="avatar-title rounded-circle bg-light">
                                <span
                                  title={""}
                                  className={
                                    "avatar-title rounded-circle bg-light bg-" +
                                    "secondary" +
                                    " text-warning" +
                                    " font-size-16" +
                                    " font-weight-semibold"
                                  }
                                >
                                  {userDetails.user_type
                                    ? userDetails.user_type.toUpperCase()
                                    : "*"}
                                </span>
                              </div>
                            </div>
                            <div>
                              {this._isPermitted("user_type") ? (
                                <InlineEdit
                                  text={
                                    userDetails.user_type
                                      ? capitalize(
                                          USER_TYPE_MAPPING[
                                            userDetails.user_type
                                          ]
                                        )
                                      : "N/A"
                                  }
                                  showHover={this._isPermitted("user_type")}
                                  onSetText={value =>
                                    this.setState({
                                      userDetails: {
                                        ...this.state.userDetails,
                                        user_type: value,
                                      },
                                    })
                                  }
                                  onSubmit={value =>
                                    this._updateUserType("type", value)
                                  }
                                  type="single-select"
                                  options={[
                                    { value: "g", label: "Ghost" },
                                    { value: "i", label: "Internal" },
                                    { value: "e", label: "External" },
                                    { value: "ib", label: "Internal Brand" },
                                    { value: "eb", label: "External Brand" },
                                  ]}
                                />
                              ) : userDetails.user_type ? (
                                capitalize(
                                  USER_TYPE_MAPPING[userDetails.user_type]
                                )
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </div>

                          <div className="d-flex align-items-center mx-2">
                            <div className="avatar-xs me-3">
                              <div className="avatar-title rounded-circle bg-light">
                                <span
                                  className={
                                    "avatar-title rounded-circle bg-light bg-" +
                                    "secondary" +
                                    " text-info" +
                                    " font-size-16" +
                                    " font-weight-semibold"
                                  }
                                >
                                  <i className="fas fa-theater-masks" />
                                </span>
                              </div>
                            </div>
                            <div>
                              {this._isPermitted("display_persona") ? (
                                <InlineEdit
                                  text={
                                    userDetails.display_persona
                                      ? capitalize(userDetails.display_persona)
                                      : "N/A"
                                  }
                                  showHover={this._isPermitted(
                                    "display_persona"
                                  )}
                                  onSetText={value =>
                                    this.setState({
                                      userDetails: {
                                        ...this.state.userDetails,
                                        display_persona: value,
                                      },
                                    })
                                  }
                                  onSubmit={value =>
                                    this._handleTagUser(
                                      "display_persona",
                                      value
                                    )
                                  }
                                  type="single-select"
                                  options={this.state.displayPersonaOptions}
                                />
                              ) : userDetails.display_persona ? (
                                capitalize(userDetails.display_persona)
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </div>
                          <div className="d-flex align-items-center mx-2">
                            <div className="avatar-xs me-3 me-3">
                              <div className="avatar-title rounded-circle bg-light">
                                <span
                                  className={
                                    "avatar-title rounded-circle bg-light bg-" +
                                    "secondary" +
                                    `${
                                      userDetails.moderation_level === -1
                                        ? " text-danger"
                                        : " text-success"
                                    }` +
                                    " font-size-16" +
                                    " font-weight-semibold"
                                  }
                                >
                                  <i className="far fa-circle" />
                                </span>
                              </div>
                            </div>
                            <div>
                              {userDetails.moderation_level === -1
                                ? "Blocked"
                                : "Active"}
                            </div>
                          </div>
                        </Row>

                        {this.state.userDetails?.tagged_social_account &&
                          this._renderSocial()}
                        <div className="border-top border-2 mt-4"></div>
                        <Row>
                          <p className="text-muted mt-1">CURATED ENTITIES</p>

                          <div>
                            <Table className="mb-0 table-wrap table-striped">
                              <tbody>
                                <tr className="text-start">
                                  <th scope="row" style={{ width: "40%" }}>
                                    Age
                                  </th>
                                  <td>
                                    {this._isPermitted("curated_age") ? (
                                      <InlineEdit
                                        text={
                                          userDetails.curated_age
                                            ? capitalize(
                                                userDetails.curated_age
                                              )
                                            : "N/A"
                                        }
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              curated_age: value,
                                            },
                                          })
                                        }
                                        showHover={this._isPermitted(
                                          "curated_age"
                                        )}
                                        onSubmit={value =>
                                          this._updateCuratedEntities(
                                            "curated_age",
                                            value
                                          )
                                        }
                                        type="single-select"
                                        options={[
                                          { label: "Mixed", value: "mixed" },
                                          { label: "Kid", value: "kid" },
                                          { label: "Adult", value: "adult" },
                                          { label: "Aged", value: "aged" },
                                        ]}
                                        disabled={["ib", "eb"].includes(
                                          this.state.userDetails.user_type
                                        )}
                                      />
                                    ) : userDetails.curated_age ? (
                                      capitalize(userDetails.curated_age)
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Gender</th>
                                  <td>
                                    {this._isPermitted("curated_gender") ? (
                                      <InlineEdit
                                        text={
                                          userDetails.curated_gender
                                            ? capitalize(
                                                userDetails.curated_gender
                                              )
                                            : "N/A"
                                        }
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              curated_gender: value,
                                            },
                                          })
                                        }
                                        showHover={this._isPermitted(
                                          "curated_gender"
                                        )}
                                        onSubmit={value =>
                                          this._updateCuratedEntities(
                                            "curated_gender",
                                            value
                                          )
                                        }
                                        type="single-select"
                                        options={[
                                          { label: "Male", value: "male" },
                                          { label: "Female", value: "female" },
                                          { label: "Mixed", value: "mixed" },
                                        ]}
                                        disabled={["ib", "eb"].includes(
                                          this.state.userDetails.user_type
                                        )}
                                      />
                                    ) : userDetails.curated_gender ? (
                                      capitalize(userDetails.curated_gender)
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Target Group</th>
                                  <td>
                                    {this._isPermitted("curated_tg") ? (
                                      <InlineEdit
                                        text={
                                          userDetails.curated_tg
                                            ? capitalize(userDetails.curated_tg)
                                            : "N/A"
                                        }
                                        showHover={this._isPermitted(
                                          "curated_tg"
                                        )}
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              curated_tg: value,
                                            },
                                          })
                                        }
                                        onSubmit={value =>
                                          this._updateCuratedEntities(
                                            "curated_tg",
                                            value
                                          )
                                        }
                                        type="single-select"
                                        options={[
                                          { label: "Rural", value: "rural" },
                                          { label: "Urban", value: "urban" },
                                          {
                                            label: "Semi Urban",
                                            value: "semi_urban",
                                          },
                                          { label: "Mixed", value: "mixed" },
                                        ]}
                                        disabled={["ib", "eb"].includes(
                                          this.state.userDetails.user_type
                                        )}
                                      />
                                    ) : userDetails.curated_tg ? (
                                      capitalize(userDetails.curated_tg)
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Original Content</th>
                                  <td>
                                    {this._isPermitted(
                                      "curated_org_content"
                                    ) ? (
                                      <InlineEdit
                                        text={
                                          userDetails.curated_org_content
                                            ? capitalize(
                                                userDetails.curated_org_content
                                              )
                                            : "N/A"
                                        }
                                        showHover={this._isPermitted(
                                          "curated_org_content"
                                        )}
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              curated_org_content: value,
                                            },
                                          })
                                        }
                                        onSubmit={value =>
                                          this._updateCuratedEntities(
                                            "curated_org_content",
                                            value
                                          )
                                        }
                                        type="single-select"
                                        options={[
                                          { label: "Yes", value: "yes" },
                                          { label: "No", value: "no" },
                                          { label: "Both", value: "both" },
                                        ]}
                                        disabled={["ib", "eb"].includes(
                                          this.state.userDetails.user_type
                                        )}
                                      />
                                    ) : userDetails.curated_org_content ? (
                                      capitalize(
                                        userDetails.curated_org_content
                                      )
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Quality</th>
                                  <td>
                                    {this._isPermitted(
                                      "curated_quality_ratio"
                                    ) ? (
                                      <InlineEdit
                                        text={
                                          userDetails.curated_quality_ratio
                                            ? capitalize(
                                                userDetails.curated_quality_ratio
                                              )
                                            : "N/A"
                                        }
                                        showHover={this._isPermitted(
                                          "curated_quality_ratio"
                                        )}
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              curated_quality_ratio: value,
                                            },
                                          })
                                        }
                                        onSubmit={value =>
                                          this._updateCuratedEntities(
                                            "curated_quality_ratio",
                                            value
                                          )
                                        }
                                        type="single-select"
                                        options={[
                                          { label: "Q1", value: "q1" },
                                          { label: "Q2", value: "q2" },
                                          { label: "Q3", value: "q3" },
                                          { label: "Q4", value: "q4" },
                                          { label: "Q5", value: "q5" },
                                        ]}
                                        disabled={["ib", "eb"].includes(
                                          this.state.userDetails.user_type
                                        )}
                                      />
                                    ) : userDetails.curated_quality_ratio ? (
                                      capitalize(
                                        userDetails.curated_quality_ratio
                                      )
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Type</th>
                                  <td>
                                    {this._isPermitted("curated_type") ? (
                                      <InlineEdit
                                        text={
                                          userDetails.curated_type
                                            ? CURATED_TYPE_MAPPING[
                                                this.state.userDetails
                                                  .curated_type
                                              ]
                                            : "N/A"
                                        }
                                        showHover={this._isPermitted(
                                          "curated_type"
                                        )}
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              curated_type: value,
                                            },
                                          })
                                        }
                                        onSubmit={value =>
                                          this._updateCuratedEntities(
                                            "curated_type",
                                            value
                                          )
                                        }
                                        type="single-select"
                                        options={this.state.curatedTypeOptions}
                                        disabled={
                                          ["ib", "eb"].includes(
                                            this.state.userDetails.user_type
                                          ) || this.state.disabled
                                        }
                                      />
                                    ) : userDetails.curated_type ? (
                                      CURATED_TYPE_MAPPING[
                                        this.state.userDetails.curated_type
                                      ]
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">X-Factor</th>
                                  <td>
                                    {this._isPermitted("curated_x_factor") ? (
                                      <InlineEdit
                                        text={
                                          userDetails.curated_x_factor
                                            ? capitalize(
                                                userDetails.curated_x_factor
                                              )
                                            : "N/A"
                                        }
                                        showHover={this._isPermitted(
                                          "curated_x_factor"
                                        )}
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              curated_x_factor: value,
                                            },
                                          })
                                        }
                                        onSubmit={value =>
                                          this._updateCuratedEntities(
                                            "curated_x_factor",
                                            value
                                          )
                                        }
                                        type="single-select"
                                        options={[
                                          { label: "Mixed", value: "mixed" },
                                          {
                                            label: "Average",
                                            value: "average",
                                          },
                                          { label: "Hot", value: "hot" },
                                          { label: "Sexy", value: "sexy" },
                                          { label: "Smart", value: "smart" },
                                          {
                                            label: "Handsome",
                                            value: "handsome",
                                          },
                                        ]}
                                        disabled={["ib", "eb"].includes(
                                          this.state.userDetails.user_type
                                        )}
                                      />
                                    ) : userDetails.curated_x_factor ? (
                                      capitalize(userDetails.curated_x_factor)
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Genre </th>
                                  <td>
                                    {this._isPermitted("curated_genre") ? (
                                      <InlineEdit
                                        component={
                                          userDetails.curated_genre
                                            ? // userDetails.curated_genre.length > 0
                                              userDetails.curated_genre.map(
                                                genre =>
                                                  CURATED_GENRE_LIST_MAPPING[
                                                    genre
                                                  ] && (
                                                    <Badge
                                                      pill
                                                      className="badge-soft-primary m-1"
                                                    >
                                                      <div className="font-size-12 p-1">
                                                        {
                                                          CURATED_GENRE_LIST_MAPPING[
                                                            genre
                                                          ]
                                                        }
                                                      </div>
                                                    </Badge>
                                                  )
                                              )
                                            : "N/A"
                                        }
                                        text={
                                          userDetails.curated_genre
                                            ? userDetails.curated_genre
                                            : ["mixed"]
                                        }
                                        onSetText={value =>
                                          this.setState({
                                            userDetails: {
                                              ...this.state.userDetails,
                                              curated_genre: value,
                                            },
                                          })
                                        }
                                        showHover={this._isPermitted(
                                          "curated_genre"
                                        )}
                                        onSubmit={value =>
                                          this._updateCuratedEntities(
                                            "curated_genre",
                                            value
                                          )
                                        }
                                        type="multi-select"
                                        options={CURATED_GENRE_LIST}
                                        disabled={["ib", "eb"].includes(
                                          this.state.userDetails.user_type
                                        )}
                                      />
                                    ) : userDetails.curated_genre ? (
                                      // userDetails.curated_genre.length > 0
                                      userDetails.curated_genre.map(
                                        genre =>
                                          CURATED_GENRE_LIST_MAPPING[genre] && (
                                            <Badge
                                              pill
                                              className="badge-soft-primary m-1"
                                            >
                                              <div className="font-size-12 p-1">
                                                {
                                                  CURATED_GENRE_LIST_MAPPING[
                                                    genre
                                                  ]
                                                }
                                              </div>
                                            </Badge>
                                          )
                                      )
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Row>
                        <div className="border-top border-2 mt-4"></div>
                        <Row>
                          <p className="text-muted mt-1">OTHER DETAILS</p>

                          <div>
                            <Table className="mb-0 table-striped">
                              <tbody>
                                <tr className="text-start">
                                  <th scope="row" style={{ width: "40%" }}>
                                    Email{" "}
                                  </th>
                                  <td>
                                    {userDetails.email
                                      ? capitalize(userDetails.email)
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Phone No</th>
                                  <td>
                                    {this.props.userDetails.phone_no !==
                                    undefined ? (
                                      <p>{this.props.userDetails.phone_no}</p>
                                    ) : (
                                      <button
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                          this.props.currentUser &&
                                            [1, 2, 11, 12, 21, 22].includes(
                                              this.props.currentUser.jotUserType
                                            ) &&
                                            this.props.dispatch(
                                              getAppUserDetails({
                                                userUuid:
                                                  this.state.filters.userUuid,
                                              })
                                            )
                                        }}
                                      >
                                        Fetch phone number
                                      </button>
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Primary Language</th>
                                  <td>
                                    {
                                      LANGUAGE_MAPPING[
                                        userDetails.user_lang_primary
                                      ]
                                    }
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Blocked </th>
                                  <td>
                                    {userDetails.moderation_level === -1
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Verified</th>
                                  <td>{userDetails.verified ? "Yes" : "No"}</td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">First Creation Date</th>
                                  <td>
                                    <Badge pill className="badge-soft-success">
                                      <div className="font-size-12 p-1">
                                        {moment(
                                          userDetails.first_content_date
                                        ).format("DD MMM YYYY") || "N/A"}
                                      </div>
                                    </Badge>
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Last Creation Date</th>
                                  <td>
                                    <Badge pill className="badge-soft-success">
                                      <div className="font-size-12 p-1">
                                        {moment(
                                          userDetails.last_content_date
                                        ).format("DD MMM YYYY") || "N/A"}
                                      </div>
                                    </Badge>
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Social Media Profile</th>
                                  <td>
                                    {userDetails.social_media_profile || "N/A"}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Social Media Score</th>
                                  <td>
                                    {userDetails.social_media_score || "N/A"}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Creator Score</th>
                                  <td>{userDetails.creator_score || "N/A"}</td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Profile Search Categories</th>
                                  <td>{userDetails.categories || "N/A"}</td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Content Languages</th>
                                  <td>
                                    {userDetails.content_create_langs &&
                                    userDetails.content_create_langs.length > 0
                                      ? userDetails.content_create_langs.map(
                                          tag => (
                                            <Badge
                                              pill
                                              className="badge-soft-primary m-1"
                                            >
                                              <div className="font-size-12 p-1">
                                                {LANGUAGE_MAPPING[tag]}
                                              </div>
                                            </Badge>
                                          )
                                        )
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Content States</th>
                                  <td>
                                    {this.state.stateMap &&
                                    userDetails.content_location_states &&
                                    userDetails.content_location_states.length >
                                      0
                                      ? userDetails.content_location_states.map(
                                          state => {
                                            if (this.state.stateMap[state]) {
                                              return (
                                                <Badge
                                                  pill
                                                  className="badge-soft-primary m-1"
                                                >
                                                  <div className="font-size-12 p-1">
                                                    {capitalize(
                                                      this.state.stateMap[state]
                                                    )}
                                                  </div>
                                                </Badge>
                                              )
                                            }
                                          }
                                        )
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Content Cities</th>
                                  <td>
                                    {this.state.cityMap &&
                                    userDetails.content_location_cities &&
                                    userDetails.content_location_cities.length >
                                      0
                                      ? userDetails.content_location_cities.map(
                                          city => {
                                            if (this.state.cityMap[city]) {
                                              return (
                                                <Badge
                                                  pill
                                                  className="badge-soft-primary m-1"
                                                >
                                                  <div className="font-size-12 p-1">
                                                    {capitalize(
                                                      this.state.cityMap[city]
                                                    )}
                                                  </div>
                                                </Badge>
                                              )
                                            }
                                          }
                                        )
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr className="text-start">
                                  <th scope="row">Content Target Categories</th>
                                  <td>
                                    {" "}
                                    {userDetails.content_target_categories &&
                                    userDetails.content_target_categories
                                      .length > 0
                                      ? userDetails.content_target_categories.map(
                                          category => (
                                            <Tag color="red">{category}</Tag>
                                          )
                                        )
                                      : "N/A"}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Row>
                      </CardBody>
                    </div>
                  </SimpleBar>
                </Col>
                <Col xl="8" sm="12" md="12" lg="12">
                  <SimpleBar style={{ height: "85vh" }}>
                    <div className="d-flex mb-3 align-items-centerh-100">
                      <div
                        role="button"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState({
                            filters: {
                              ...this.state.filters,
                              pageType: "video",
                            },
                          })
                        }
                        className={
                          this.state.filters.pageType === "video"
                            ? "text-black mx-3 font-size-18"
                            : "text-muted mx-3 font-size-16"
                        }
                      >
                        <i
                          className="fas fa-video"
                          style={{ marginRight: "5px" }}
                        />
                        Videos
                      </div>

                      <div
                        role="button"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.setState({
                            filters: {
                              ...this.state.filters,
                              pageType: "changelog",
                            },
                          })
                        }}
                        className={
                          this.state.filters.pageType === "changelog"
                            ? "text-black d-flex align-items-center mx-3 font-size-18"
                            : "text-muted d-flex align-items-center mx-3 font-size-16"
                        }
                      >
                        <i
                          className="fas fa-tasks"
                          style={{ marginRight: "5px" }}
                        />
                        Changelog
                      </div>

                      {/* <div
                        role="button"
                        style={{ cursor: "pointer" }}
                        // onClick={() =>
                        //   this.setState({
                        //     filters: {
                        //       ...this.state.filters,
                        //       pageType: "statistics",
                        //     },
                        //   })
                        // }
                        className={
                          this.state.filters.pageType === "statistics"
                            ? "text-black d-flex align-items-center mx-3 font-size-18"
                            : "text-muted d-flex align-items-center mx-3 font-size-17"
                        }
                      >
                        <i
                          className="bx bx-bar-chart"
                          style={{ marginRight: "5px" }}
                        />
                        Statistics
                      </div> */}
                    </div>
                    {this._renderPages()}
                  </SimpleBar>
                </Col>
              </Row>
            )}
          </Container>
        )}
        {this.state.showForm && this._getForm()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    locationList: _.get(store, "locationReducer.locationList"),
    loading: _.get(store, "locationReducer.loading"),
    loadingUser: _.get(store, "appUser.loading"),
    refetchData: _.get(store, "appUser.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    userDetails: _.get(store, "appUser.userDetails"),
    createdUserUUID: _.get(store, "appUser.createdUserUUID"),
    userVideos: _.get(store, "appUser.userVideos"),
    logs: _.get(store, "appUser.logs"),
    totalUserVideos: _.get(store, "appUser.totalUserVideos"),
  }
}

export default connect(mapStateToProps)(AppUserDetails)
